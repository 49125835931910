<template>
  <div v-if="$store.state.toast.show" class="rdnb-toast" :class="[$store.state.toast.class]" style="animation-duration: .3s;">
    <div class="toast-container">
      <span>{{ $store.state.toast.message }}</span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.rdnb-toast {
  position: fixed;
  pointer-events: none;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000000001;
  >.toast-container {
    position: relative;
    >span {
      display: block;
      margin: 40px auto 0 auto;
      padding: 7px 20px;
      border-radius: 12px;
      background: rgba(0, 0, 0, .8);
      font-size: 12px;
      letter-spacing: .3px;
      color: #fff;
      text-align: center;
      max-width: 90%;
      width: 300px;
      box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, .5);
    }
  }
}
</style>
